import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import 'react-quill/dist/quill.snow.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminRoute from './routes/AdminRoute';
import './app.css';

import LoginScreen from './screens/auth/LoginScreen';
import Clients from './screens/admin/clients/Clients';
import AddClient from './screens/admin/clients/AddClient';
import Client from './screens/admin/clients/Client';

import InvitationClient from './screens/invitation-client/InvitationClient';
import WeddingClient from './screens/wedding-client/WeddingClient';
import MyWedding from './screens/wedding-client/MyWedding';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<LoginScreen />} />
        <Route path="/login" exact element={<LoginScreen />} />
        
        <Route path="/admin/clients" exact element={<AdminRoute><Clients /></AdminRoute>} />
        <Route path="/admin/add-client" exact element={<AdminRoute><AddClient /></AdminRoute>} />
        <Route path="/admin/client/:id" exact element={<AdminRoute><Client /></AdminRoute>} />


        <Route path="/invitation/client/:slug" exact element={<InvitationClient />} />
        <Route path="/wedding/client/:slug" exact element={<WeddingClient />} />
        <Route path="/my-wedding/:slug" exact element={<MyWedding />} />
      </Routes>
    </Router>
  )
}

export default App