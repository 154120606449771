import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Input, Button, Tooltip, Table } from 'antd';
import { useGetClientsQuery, useDeleteClientMutation } from '../../../slices/appApiSlice';
const { Search } = Input;

const Clients = () => {
  const navigate = useNavigate();
  const { data: clients, isLoading } = useGetClientsQuery();
  const [deleteClient, { isLoadingDelete }] = useDeleteClientMutation();
  const [search, setSearch] = useState('');
  const [searchData, setSearchData] = useState([]);

  const handleDelete = async (departmentId) => {
    try {
      await deleteClient(departmentId).unwrap();
    } catch (error) {
      console.log("error", error);
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (type) => type === 'invitation' ? 'Invitation' : 'Wedding'
    },
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      render: (id) => (
        <div className='d-flex'>
          <Button type="primary" size='small' onClick={() => navigate(`/admin/client/${id}`)} disabled={isLoading} loading={isLoadingDelete}>
            Show
          </Button>
          <div className='mx-1'></div>
          <Button danger type="primary" size='small' onClick={() => handleDelete(id)} disabled={isLoading} loading={isLoadingDelete}>
            Delete
          </Button>
        </div>
      ),
    }
  ];

  useEffect(() => {
    if (search !== '') {
      const filtered = clients.filter(item => {
        return (
          item.name.toLowerCase().includes(search.toLowerCase()) ||
          item.phone.toLowerCase().includes(search.toLowerCase())
        );
      });
      setSearchData(filtered);
    }
  }, [search, clients]);

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center'>
        <div style={{ width: '70%' }}>
          <Search
            placeholder="Search client"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div>
          <Tooltip title="Add Client">
            <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={() => navigate('/admin/add-client')} />
          </Tooltip>
        </div>
      </div>
      <div className='mt-3'>
        <Table scroll={true} dataSource={search !== '' ? searchData : clients} columns={columns} loading={isLoading} bordered pagination={false} />
      </div>
    </div>
  )
}

export default Clients;