import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLoginMutation } from '../../slices/authApiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setCredentials } from '../../slices/authSlice';
import loginBackgroundImage from '../../assets/login-background.png';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input } from 'antd';

const LoginScreen = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [login, { isLoading }] = useLoginMutation();
  const { user } = useSelector((state) => state.auth);

  const onFinish = async (values) => {
    try {
      const res = await login(values).unwrap();
      dispatch(setCredentials({...res}));
    } catch (error) {
        console.log("error", error);
    }
  };

  useEffect(() => {
    if (user && user.role === 'admin') {
      navigate('/admin/clients');
    }
  }, [user, navigate]);

  return (
    <div className={styles.LoginPageWrapper}>
      <div className={styles.LoginFormWrapper}>
        <div className={styles.Form}>
          <h4>{t('login')}</h4>
          <p></p>
          <Form
            layout='vertical'
            form={form}
            onFinish={onFinish}
            scrollToFirstError
          >
            <Form.Item
              label="Email"
              required={false}
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'The input is not valid E-mail!',
                },
                {
                  required: true,
                  message: 'Please input your E-mail!',
                },
              ]}
            >
              <Input placeholder='Email' />
            </Form.Item>

            <Form.Item
              label="Password"
              required={false}
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your Password!',
                },
              ]}
            >
              <Input.Password placeholder='Password' />
            </Form.Item>

            <Button type="primary" htmlType="submit" className='mt-5' style={{ width: '100%'}}>
              {t('login')}
            </Button>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default LoginScreen