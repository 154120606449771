import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Form, Input, Button, Tooltip, Card, Spin, Image } from 'antd';
import { useStoreClientImageMutation, useGetClientQuery, useDeleteClientImageMutation, useUpdateClientMutation } from '../../../slices/appApiSlice';
import { QRCode } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Client = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [formUpdate] = Form.useForm();
  const [image, setImage] = useState();
  const [description, setDescription] = useState();

  const [clientName, setClientName] = useState();
  const [clientPhone, setClientPhone] = useState();
  const [clientLocation, setClientLocation] = useState();

  const [storeImage, { isLoading }] = useStoreClientImageMutation();
  const { data: client, isLoadingClient, refetch } = useGetClientQuery(id);
  const [deleteClientImage, { isLoadingDelete }] = useDeleteClientImageMutation();
  const [updateClient, { isLoadingUpdateClient }] = useUpdateClientMutation();


  const onFinish = async () => {
    const data = new FormData();
    if (!image) {
        return;
    }
    data.append('image', image);
    data.append('description', description ?? '');
    data.append('client_id', id);

    try {
      await storeImage(data).unwrap();
      setDescription('');
      setImage(null);
      document.getElementById('fileInput').value = '';
    } catch (error) {
        console.log("error", error);
    }
  };

  const onUpdateClientInfo = async () => {
    const data = {
      id: client.id,
      location: clientLocation,
      phone: clientPhone,
      name: clientName
    };
    try {
      await updateClient(data).unwrap();
      refetch();
    } catch (error) {
        console.log("error", error);
    }
  };

  const uploadImage = (e) => {
    setImage(e.target.files[0]);
  }

  const handleClientImageDelete = async (id) => {
    try {
      await deleteClientImage(id).unwrap();
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (client) {
      setClientLocation(client.location);
      setClientName(client.name);
      setClientPhone(client.phone);
    }
  }, [client]);

  const downloadQRCode = () => {
    const canvas = document.getElementById('myqrcode')?.querySelector('canvas');
    if (canvas) {
      const url = canvas.toDataURL();
      const a = document.createElement('a');
      a.download = `QRCode-${client.name}.png`;
      a.href = url;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };

  return (
    client && <div>
        <div className='d-flex justify-content-end'>
          <Tooltip title="Back">
            <Button type="primary" shape="circle" icon={<ArrowLeftOutlined />} onClick={() => navigate('/admin/clients')} />
          </Tooltip>
        </div>
        {isLoadingClient ? (
          <div className='text-center'>
            <Spin />
          </div>
        ) : (
          <>
            <div className='mt-3'>
                <Card
                    title="Client Info | Client Images"
                    bordered
                >
                  <div className='row'>
                    <div className='col-md-8'>
                      <Form
                        layout='vertical'
                        form={formUpdate}
                        onFinish={onUpdateClientInfo}
                        scrollToFirstError
                      >
                        <Input required placeholder='Client Name' value={clientName} onChange={(e) => setClientName(e.target.value)} />
                        <Input required className='mt-2' placeholder='Client Phone' value={clientPhone} onChange={(e) => setClientPhone(e.target.value)} />
                        {client.type === 'invitation' && <Input className='mt-2' placeholder='Client Location' value={clientLocation} onChange={(e) => setClientLocation(e.target.value)} />}
                        <h6 className='mt-3'>Type: <b>{client.type}</b></h6>
                        <div className='d-flex justify-content-center'>
                          <Button type="primary" size='medium' htmlType="submit" className='mt-3' loading={isLoadingUpdateClient}>
                              Update Client Info
                          </Button>
                        </div>
                        {client.type === 'wedding' && (
                          <div className='my-5'>
                            <CopyToClipboard text={`https://me-scano.com/my-wedding/${client.slug}`}>
                              <Button>
                                Click and copy link for client to see images
                              </Button>
                            </CopyToClipboard>
                            <p className='mt-2'>Password: <b>{client.password}</b></p>
                          </div>
                        )}
                      </Form>
                    </div>
                    <div className='col-md-4 d-flex justify-content-center'>
                      <div id="myqrcode">
                        <QRCode
                          bgColor="#fff"
                          value={`https://me-scano.com/${client.type}/client/${client.slug}`}
                        />
                        <div className='text-center'>
                          <Button onClick={downloadQRCode}>
                            Download
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
            </div>
            <div className='mt-3 mb-5'>
              <Card
                title="Add new image"
                bordered
              >
                <Form
                  layout='vertical'
                  form={form}
                  onFinish={onFinish}
                  scrollToFirstError
                >
                  
                  {/* <Input placeholder='Description' value={description} onChange={(e) => setDescription(e.target.value)} /> */}
                  <input
                    id="fileInput"
                    type="file"
                    className='mt-3'
                    onChange={(e) => uploadImage(e)}
                    required
                  />

                  <div className='d-flex justify-content-center'>
                    <Button type="primary" size='medium' htmlType="submit" className='mt-3' loading={isLoading}>
                        Save Image
                    </Button>
                  </div>
                </Form>   
                <hr></hr>
                  {client.images.length > 0 && (
                      <div className='row'>
                          {client.images.map((img, index) => (
                              <div key={index} className='col-md-3'>
                                  <Image
                                    width='100%'
                                    src={img.imageUrl}
                                  />
                                  <div className='text-center mt-1'>
                                    <Button loading={isLoadingDelete} disabled={isLoadingDelete} danger onClick={() => handleClientImageDelete(img.id)}>Delete</Button>
                                  </div>
                              </div>
                          ))}
                      </div>
                  )}
              </Card>
            </div>
          </>
        )}
    </div>
  )
}

export default Client;