import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Spin, Carousel, Button, Input } from 'antd';
import { useGetOpenClientWeddingQuery } from '../../slices/appApiSlice';
import styles from './styles.module.scss';

const MyWedding = () => {
  const { slug } = useParams();
  const { data: client, isLoadingClient } = useGetOpenClientWeddingQuery(slug);
  const carouselRef = React.createRef();

  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const onConfirmPassword = () => {
    if (password === client.password) {
        setConfirm(true);
        setError(false);
    } else {
        setPassword('');
        setConfirm(false);
        setError(true);
    }
  }

  return (
    isLoadingClient ? (
        <div className='text-center'>
            <Spin />
        </div>
    ) : (
        <>
            {client && confirm && (
                <div className={styles.InvitationClientWrapper}>
                    <div className='d-flex align-items-center'>
                        <Carousel ref={carouselRef} style={{ height: '100%', width: '100vw' }}>
                            {client && client.images && client.images.map((item) => (
                                <img alt={`img${item.id}`} src={item.imageUrl} width="100%" style={{ maxHeight: '100%' }} />
                            ))}
                        </Carousel>
                    </div>
                    <div style={{ padding: '10px', borderTop: '1px solid black' }} className='d-flex justify-content-center align-items-center'>
                        <div>
                            <div className='text-center mb-2'>
                                <Button
                                    onClick={() => {
                                        carouselRef.current.prev();
                                    }}
                                    icon={<ArrowLeftOutlined />}
                                />
                                <Button
                                    onClick={() => {
                                        carouselRef.current.next();
                                    }}
                                    icon={<ArrowRightOutlined />}
                                />
                            </div>
                            <p style={{ cursor: 'pointer' }}>made by <b>me.scano</b></p>
                        </div>
                    </div>
                </div>
            )}
            {client && !confirm && (
                <div style={{ height: '100vh' }}>
                    <div style={{ height: '100%', width: '100%' }} className='d-flex align-items-center justify-content-center'>
                        <div className='text-center'>
                            <Input className='my-2' placeholder='Fjalekalimi ...' onChange={(e) => setPassword(e.target.value)} value={password} />
                            {error && <p>Fjalekalim i gabuar... Kontaktoni ME SCANO</p>}
                            <Button type="primary" size='large' onClick={() => onConfirmPassword()}>
                                Konfirmo
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
  )
}

export default MyWedding;