import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({ 
    baseUrl: "https://api.me-scano.com/api",
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.accessToken;
        if (token) {
            headers.append("Authorization", `Bearer ${token}`)
        }
        return headers;
    }
});

export const apiSlice = createApi({
    baseQuery,
    tagTypes: ['Clients', 'Client'],
    endpoints: (builder) => ({})
});