import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Form, Input, Button, Tooltip, Card, Select } from 'antd';
import { useCreateClientMutation } from '../../../slices/appApiSlice';

const AddClient = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [selectedType, setSelectedType] = useState(null);
  const [createClient, { isLoading }] = useCreateClientMutation();

  const onFinish = async (values) => {
    const data = {
        name: values.name,
        phone: values.phone,
        type: values.type
    };
    try {
      console.log("data", data);
      await createClient(data).unwrap();
      navigate('/admin/clients')
    } catch (error) {
        console.log("error", error);
    }
  };

  const handleTypeChange = (value) => {
    setSelectedType(value);
  };

  return (
    <div>
        <div className='d-flex justify-content-end'>
          <Tooltip title="Back">
            <Button type="primary" shape="circle" icon={<ArrowLeftOutlined />} onClick={() => navigate('/admin/clients')} />
          </Tooltip>
        </div>
        <div className='mt-3'>
          <Card
            title="Add new client"
            bordered
          >
            <Form
              layout='vertical'
              form={form}
              onFinish={onFinish}
              scrollToFirstError
            >
              <Form.Item
                required={false}
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Client name is required',
                  },
                ]}
              >
                <Input placeholder='Client name' />
              </Form.Item>

              <Form.Item
                required={false}
                name="phone"
                rules={[
                  {
                    required: true,
                    message: 'Phone is required',
                  },
                ]}
              >
                <Input placeholder='Phone' />
              </Form.Item>

              <Form.Item
                  required={false}
                  name="type"
                  rules={[
                  {
                      required: true,
                      message: 'Type is required',
                  },
                  ]}
              >
                  <Select options={[
                      {
                        value: 'invitation',
                        label: 'Invitation'
                      },
                      {
                        value: 'wedding',
                        label: 'Wedding'
                      }
                    ]} 
                    placeholder='Select type' 
                    onChange={handleTypeChange}
                  />
              </Form.Item>

              {selectedType === 'invitation' && (
                <Form.Item
                  required={false}
                  name="location"
                >
                  <Input placeholder='Location' />
                </Form.Item>
              )}

              <div className='d-flex justify-content-center'>
                  <div>
                      <div>
                          <Button type="primary" size='large' htmlType="submit" className='mt-3' loading={isLoading}>
                              Create Client
                          </Button>
                      </div>
                  </div>
              </div>
            </Form>   
          </Card>
        </div>
    </div>
  )
}

export default AddClient;