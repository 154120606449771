import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { SendOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { Spin, Carousel, Button, Input } from 'antd';
import { useDispatch } from 'react-redux';
import { useStoreOpenClientImageMutation } from '../../slices/appApiSlice';
import step1Background from './step1.png';
import thanks from './thanks.png';
import styles from './styles.module.scss';

const WeddingClient = () => {
    const { slug } = useParams();
    const dispatch = useDispatch();

    const [step, setStep] = useState(1);
    const [image, setImage] = useState();
    const [description, setDescription] = useState();
    const [selectedImage, setSelectedImage] = useState(null);

    const [storeImage, { isLoading }] = useStoreOpenClientImageMutation();

    const uploadImage = (e) => {
        const file = e.target.files[0];
        setImage(file);
        const reader = new FileReader();

        reader.onload = () => {
            setSelectedImage(reader.result);
        };

        reader.readAsDataURL(file);
        setStep(2);
        // dispatch(toggleLoading(true));
        // const formData = new FormData()
        // formData.append('client_id', props.client.id);
        // formData.append('image', e.target.files[0]);
        // dispatch(storeImageOfClient(formData)).then(res => {
        //     toast.success("Foto u shtua me sukses!")
        //     dispatch(toggleLoading(false));
        //     fetchData();
        // }).catch(error => {
        //     dispatch(toggleLoading(false));
        //     toast.error("Dicka shkoi keq, ju lutem provoni perseri!")
        // });
    }

    const onSend = async () => {
        const formData = new FormData()
        formData.append('client_slug', slug);
        formData.append('image', image);
        formData.append('description', description);
        try {
            await storeImage(formData).unwrap();
            setDescription("");
            setSelectedImage();
            setImage();
            setStep(3);
        } catch (error) {
            console.log("error", error);
        }
    }

    return (
        <div style={{ height: '100vh' }}>
            <div style={{ height: '100%' }} className='d-flex align-items-center justify-content-center'>
                {step === 1 && (
                    <div style={{ backgroundImage: `url(${step1Background})`, height: '100%', width: '100%', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}} className='d-flex align-items-end justify-content-center'>
                        <label htmlFor="file-input" className={styles.customFileUpload} style={{ marginBottom: '30%' }}>
                            <PlusCircleOutlined style={{ fontSize: '22px', paddingRight: '15px'}} />
                            Bashkangjit Foto
                        </label>
                        <input
                            id="file-input"
                            type="file"
                            onChange={uploadImage}
                            hidden
                        />
                    </div>
                )}
                {step === 2 && (
                    selectedImage && (
                        <div className='text-center' style={{ backgroundColor: '#E9E5C5', height: '100%', width: '100%' }}>
                            <div style={{ height: '70%', }}>
                                <img src={selectedImage} alt="Selected" style={{ height: '60%', border: '20px solid #fff', transform: 'rotate(-20deg)', marginTop: '10%', marginBottom: '10%' }} />

                                <div className='px-3'>
                                    <Input className='my-2' placeholder='Pershkrimi ...' style={{ backgroundColor: '#E9E5C5', border: 'none' }} />
                                </div>
                            </div>

                            <div style={{ height: '30%' }}>
                                <Button type="primary" size='large' style={{ backgroundColor: '#E9E5C5', padding: '15px 60px', height: '54px', color: '#1f1f1f', borderColor: '#1f1f1f', boxShadow: 'none' }} onClick={() => onSend()} loading={isLoading} disabled={isLoading}>
                                    Përfundo
                                </Button>
                            </div>
                        </div>
                    )
                )}
                {step === 3 && (
                    <div className='text-center' style={{ backgroundColor: '#E9E5C5', height: '100%', width: '100%' }}>
                        <div style={{ height: '50%' }} className='d-flex justify-content-center align-items-end'>
                            <img src={thanks} alt="thankyou" /> 
                        </div>

                        <div style={{ height: '50%' }}>
                            <Button type="primary" size='large' style={{ backgroundColor: '#E9E5C5', padding: '15px 60px', marginTop: '20%', height: '54px', color: '#1f1f1f', borderColor: '#1f1f1f', boxShadow: 'none' }} onClick={() => setStep(1)}>
                                Dërgo Përsëri
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default WeddingClient;