import { apiSlice } from "./apiSlice";

export const appApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getClients: builder.query({
            query: () => ({
                url: '/clients',
            }),
            providesTags: ['Clients'],
            keepUnusedDataFor: 60 * 60 * 24 * 365 * 10
        }),
        getClient: builder.query({
            query: (id) => ({
                url: `/clients/${id}`,
            }),
            providesTags: ['Client'],
            keepUnusedDataFor: 60 * 60 * 24 * 365 * 10
        }),
        getOpenClient: builder.query({
            query: (slug) => ({
                url: `/open-clients/${slug}`,
            }),
            providesTags: ['Client'],
            keepUnusedDataFor: 60 * 60 * 24 * 365 * 10
        }),
        getOpenClientWedding: builder.query({
            query: (slug) => ({
                url: `/open-clients-wedding/${slug}`,
            }),
            providesTags: ['Client'],
            keepUnusedDataFor: 60 * 60 * 24 * 365 * 10
        }),
        createClient: builder.mutation({
            query: (data) => ({
                url: `/clients`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Clients'],
        }),
        updateClient: builder.mutation({
            query: (data) => ({
                url: `/clients/${data.id}`,
                method: 'PUT',
                body: data
            }),
            invalidatesTags: ['Clients'],
        }),
        deleteClient: builder.mutation({
            query: (id) => ({
                url: `/clients/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Clients'],
        }),
        storeClientImage: builder.mutation({
            query: (data) => ({
                url: `/image-client`,
                method: 'POST',
                body: data
            }),
            invalidatesTags: ['Client'],
        }),
        storeOpenClientImage: builder.mutation({
            query: (data) => ({
                url: `/open-image-client`,
                method: 'POST',
                body: data
            }),
        }),
        deleteClientImage: builder.mutation({
            query: (id) => ({
                url: `/image-client/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Client'],
        }),
    })
});

export const { useCreateClientMutation, useUpdateClientMutation, useGetOpenClientWeddingQuery, useStoreOpenClientImageMutation, useGetClientsQuery, useDeleteClientMutation, useStoreClientImageMutation, useGetOpenClientQuery, useGetClientQuery, useDeleteClientImageMutation } = appApiSlice;