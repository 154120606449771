import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null,
    accessToken: localStorage.getItem('accessToken') ? JSON.parse(localStorage.getItem('accessToken')) : null
};

const authSlice = createSlice({
    name: 'Auth',
    initialState,
    reducers: {
        setCredentials: (state, action) => {
            state.user = action.payload.user;
            state.accessToken = action.payload.accessToken
            localStorage.setItem('user', JSON.stringify(action.payload.user));
            localStorage.setItem('accessToken', JSON.stringify(action.payload.accessToken));
        },
        setUserInfo: (state, action) => {
            state.user = action.payload;
            localStorage.setItem('user', JSON.stringify(action.payload));
        },
        logout: (state) => {
            state.user = null;
            state.accessToken = null;
            localStorage.removeItem('user');
            localStorage.removeItem('accessToken');
        }
    }
});

export const { setCredentials, logout, setUserInfo } = authSlice.actions;

export default authSlice.reducer;