import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './styles.module.scss';
import { logout } from '../slices/authSlice';
import { useDispatch } from 'react-redux';
import LogoutIcon from '../assets/icons/logout.svg';
import HomeIcon from '../assets/icons/home.svg';
import { Layout, Tooltip, Popover } from 'antd';

const AdminRoute = ({ children }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { pathname } = location;
    const { user } = useSelector((state) => state.auth);
    const { Header, Content, Sider } = Layout;
    
    // return user && user.role_id === 2 ? <div>
    //     {children}
    // </div> : <Navigate to="/login" />

    return user && user.role === 'admin' ? (
        <div>
            <div className={styles.HeaderWrapper}>
                <img src={LogoutIcon} style={{ cursor: 'pointer' }} onClick={() => dispatch(logout())} width='30px' alt='logouticon' />
            </div>
            <div className='container mt-3'>
                {children}
            </div>
        </div>
    ) : <Navigate to="/login" />;
};

export default AdminRoute;