import React from 'react';
import ReactDOM from 'react-dom/client';
import "rsuite/dist/rsuite.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastContainer } from 'react-toastify';
import "./i18n";
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ConfigProvider
        theme={{
          components: {
            Form: {
              labelFontSize: '13px',
              controlHeight: 40,
              fontSize: '12px'
            },
            Button: {
              colorPrimary: '#554D8A',
              colorPrimaryHover: '#7062CD',
              colorPrimaryActive: '#7062CD',
            },
            Input: {
              colorPrimary: '#9181F4',
              controlHeight: 40
            },
            DatePicker: {
              colorPrimary: '#9181F4',
              controlHeight: 40
            },
            Switch: {
              colorPrimary: '#9181F4',
              handleSize: 25,
              trackHeight: 30,
              innerMinMargin: 10,
              innerMaxMargin: 33,
              colorTextQuaternary: '#D6D6D6',
            },
            InputNumber: {
              colorPrimary: '#9181F4',
              controlHeight: 40
            },
            Select: {
              colorPrimary: '#9181F4',
              controlHeight: 40
            },
            ColorPicker: {
              colorPrimary: '#9181F4',
              controlHeight: 40
            },
            Menu: {
              itemBg: '#EBEDEF',
              itemColor: '#000',
              itemSelectedBg: '#fff',
              itemSelectedColor: '#554D8A',
            },
            Layout: {
              headerBg: '#554D8A',
              siderBg: '#EBEDEF'
            }
          },
        }}
      >
      <App />
      </ConfigProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();