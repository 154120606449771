import React from 'react';
import { useParams } from 'react-router-dom';
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Spin, Carousel, Button } from 'antd';
import { useGetOpenClientQuery } from '../../slices/appApiSlice';
import locationImg from "./location.png";
import styles from './styles.module.scss';

const InvitationClient = () => {
  const { slug } = useParams();
  const { data: client, isLoadingClient } = useGetOpenClientQuery(slug);
  const carouselRef = React.createRef();

  return (
    isLoadingClient ? (
        <div className='text-center'>
            <Spin />
        </div>
    ) : (
        client && (
            <div className={styles.InvitationClientWrapper}>
                <div style={{ padding: '10px', borderBottom: '1px solid black' }} className='d-flex justify-content-between align-items-center'>
                    <div></div>
                     {client.location && <a className={styles.locationButton} target="_blank" href={client.location}><img width="100px" src={locationImg} /></a>}
                </div>
                <div className='d-flex align-items-center'>
                    <Carousel ref={carouselRef} style={{ height: '100%', width: '100vw' }}>
                        {client && client.images && client.images.map((item) => (
                            <img alt={`img${item.id}`} src={item.imageUrl} width="100%" style={{ maxHeight: '100%' }} />
                        ))}
                     </Carousel>
                </div>
                <div style={{ padding: '10px', borderTop: '1px solid black' }} className='d-flex justify-content-center align-items-center'>
                    <div>
                        <div className='text-center mb-2'>
                            <Button
                                onClick={() => {
                                    carouselRef.current.prev();
                                }}
                                icon={<ArrowLeftOutlined />}
                            />
                            <Button
                                onClick={() => {
                                    carouselRef.current.next();
                                }}
                                icon={<ArrowRightOutlined />}
                            />
                        </div>
                        <p style={{ cursor: 'pointer' }}>made by <b>me.scano</b></p>
                    </div>
                </div>
            </div>
        )
    )
  )
}

export default InvitationClient;